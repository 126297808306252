import Vue from 'vue'
import Vuex from 'vuex'
// import Session from './modules/session'
// import StaticData from './modules/staticData'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cachedPerfumes: {},
    locale: null,
    availableLocales: null,
    isoCountries: null,
    defaultLocale: {"name":"English","id":"EN","image_url":"https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/250px-Flag_of_the_United_Kingdom.svg.png"},
    unsavedChanges: false,
    nextPage: null,
    token: null
  },

  modules: {
    // staticData: StaticData,
    // session: Session,
  },
  mutations:{
    saveById(state, data) {
      state.cachedPerfumes[data.id] = data.data
    },
    setLocale( state, payload ) {
      state.locale = payload;
    },
    setAvailableLocales( state, payload ) {
      state.availableLocales = payload;
    },
    setIsoCountries( state, payload ) {
      state.isoCountries = payload;
    },
    setUnsavedChanges( state, payload ) {
      state.unsavedChanges = payload;
    },
    setNextPage( state, payload ) {
      state.nextPage = payload;
    },
    setToken( state, payload ){
      state.token = payload;
    }
  },
  actions:{
    saveById(context, data) {
      context.commit('saveById', data)
    },
  },
  getters: {
    getById: state => (id) =>{
      return state.cachedPerfumes[id]
    },
    locale( state ) {
      return state.locale;
    },
    availableLocales( state ) {
      return state.availableLocales;
    },
    isoCountries( state ) {
      return state.isoCountries;
    },
    defaultLocale( state ) {
      return state.defaultLocale;
    },
    unsavedChanges( state ) {
      return state.unsavedChanges;
    },
    nextPage( state ) {
      return state.nextPage;
    },
    token( state ){
      return state.token;
    }
    // activeFamilyCartridges: state => () => {
    //   let currentMixes = state.staticData.mixData.find((m)=>m.ean === state.session.activeFamily.ean)
    //   let result = state.staticData.cartridges.reduce((res,c)=>{
    //     let mixData = currentMixes.mixes.find((cc)=>cc.eans[1] === c.ean)
    //     if(mixData) res.push({
    //       ...c,
    //       mixData: mixData
    //     })
    //     return res
    //   },[])
    //   return result
    // }
  }
})
