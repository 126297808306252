<template>
  <div
    itemscope
    itemtype="http://schema.org/Product"
    class="perfume-card"
    @click="$emit('click',data)"
  >
    <FamiliesWithIcon
      itemprop="additionalProperty"
      :family="data.family"
      :subfamily="data.secondaryFamily"
    ></FamiliesWithIcon>
    <div class="image" :style="{backgroundImage: `url(${data.image_url})`}"></div>
    <div itemprop="name" class="name">{{data.name}}</div>
    <div itemprop="brand" class="brand">{{data.brand.name}}</div>
    <div class="remove-button" v-if="showRemove" @click.stop="$emit('remove',data)"></div>
  </div>
</template>

<script>
import FamiliesWithIcon from '@/components/wikiparfum/FamiliesWithIcon'
export default {
  name: 'PerfumeCard',
  components:{
    FamiliesWithIcon
  },
  props: ['data','showRemove'],
  methods:{
  }
}
</script>

<style scoped lang="stylus">
.perfume-card
  align-items: center
  background-color: #fff
  border: 1px solid rgba(#000, 0.15)
  cursor: pointer
  display: flex
  flex-direction: column
  overflow: hidden
  padding: 2.415458937198068vw
  position: relative

  .image
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    flex: 1 1 0%
    margin: 1.358695652173913vh 0
    width: 100%

    // .mobile &
    //   margin: 5.434782608695651vh 0

  .name
    m-ellipsis(100%)
    font-weight: 500
    m-ellipsis(100%)
    m-font-size(14, 20)
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calc(1.69082vw + .95109vh);
    line-height: calc(2.41546vw + 1.3587vh);

  .brand
    m-ellipsis(100%)
    m-font-size(11, 13)
    text-transform: uppercase
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calc(1.3285vw + .74728vh);
    line-height: calc(1.57005vw + .88315vh);

  .remove-button
    cursor: pointer
    m-icon("close", 15)
    position: absolute
    right: 0.966183574879227vw

  .family-and-icon
    width: 100%
</style>
