<template>
  <div class="similar-perfumes">
    <LoadingOverlay v-if="loading" text="loading_perfumes" />
    <template v-if="results">
      <div class="results-header">
        <div class="title">These are your matches:</div>
<!--        <div class="subtitle">{{t("promo_code_2")}}</div>-->
      </div>
      <div class="register-container">
        <div class="c-button c-button__1" style="transform: translate(0px, 0px);" @click="goToRegister()">
          <div class="c-button__text">register now</div>
        </div>
      </div>
      <div class="similars-list" v-if="filteredResults">
        <div class="scroll-wrapper">
          <PerfumeCard
            v-for="(perfume, index) in filteredResults"
            :key="index"
            :data="perfume"
            @click="$emit('select',perfume)"
          ></PerfumeCard>
        </div>
        <div class="no-results" v-if="filteredResults.length===0 && !loading">
          <div class="text">{{t("no_results")}}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import ListFilters from '@/components/wikiparfum/ListFilters'
import PerfumeCard from '@/components/wikiparfum/PerfumeCard'
// import {getSimilarPerfumes, getSimilarByIngredients, getSimilarByText, getAllBrands} from '@/services/api'
import LoadingOverlay from '@/components/wikiparfum/LoadingOverlay'
import Api from "../../Api";
// import {applyFilters} from '@/utils'
// import {trackProfileRecomentadions,trackRecommendations,trackIngredientsUsedToFindPerfum} from '@/services/track'
// import { GENDER_ENUM } from '@/utils/enums'
export default {
  name: 'SimilarPerfumes',
  components:{
    PerfumeCard,
    // ListFilters,
    LoadingOverlay
  },
  props: {
    results: [Array],
    reference: [Object,Array,String],
    type: String,
    selectId:[String,Number]

  },

  data (){
    return {
      filterSelection: null,
      loading: false
    }
  },
  computed:{
    filteredResults (){
      // return this.results && applyFilters(this.results,this.filterSelection) || []
      // return applyFilters(this.results,this.filterSelection) || []
      return this.results
    }
  },
  asyncComputed:{
    filterDefs(){
      // return getAllBrands().then((brands)=>{
      //   return [
      //     {
      //       id: 'gender',
      //       filterLabel: 'filter_by_gender',
      //       type: 'options',
      //       fixedOptions:[{value:GENDER_ENUM.FEMALE,customLabel:'for_her'},{value:GENDER_ENUM.MALE,customLabel:'for_him'},{value:GENDER_ENUM.UNISEX,customLabel:'unisex'}],
      //       keyPath: 'gender',
      //       validation:(value,object) => value.indexOf(object)>=0
      //     },
      //     {
      //       id: 'family',
      //       filterLabel: 'filter_by_family',
      //       type: 'options',
      //       keyPath: 'family.id',
      //       labelPath: 'family.name'
      //     },
      //     {
      //       id: 'brand',
      //       filterLabel: 'filter_by_brand',
      //       type: 'options',
      //       fixedOptions: brands.map((b)=>({value:b.id,label:b.name})),
      //       keyPath: 'brand.id',
      //       female:true,
      //     }
      //   ]
      // })

    }
  },
  methods:{
    getById (id) {
      if(this.filteredResults) return this.filteredResults.find((p)=>p.id == id)
      else return null
    },
    goToRegister () {
      // Increment counter
      const WEBSITE_NAME = 'FRAGRANCE_FINDER';
      const TYPE = 'register';
      const ep = `/visitorcounter?type=${TYPE}&name=${WEBSITE_NAME}`;
      Api.post(ep)
          .catch(err => console.log(err));
      // Navigate
      const RETAILER_URL = 'https://sso.dufry.com/register?source=_Offline_Airparfum_t4_sat&campaign=marzo_2023';
      window.open(RETAILER_URL, '_blank');
    }
  },
  watch:{
    filterSelection(){
      console.log(this.filterSelection)
    }
  }
}
</script>

<style scoped lang="stylus">
.similar-perfumes
  // min-height: 100% //WORNG!! no se porque, pero hacia aparecer un scroll de mas.... :(
  position: relative

  // display: flex
  // flex-direction: column
  .results-header
    align-items: center
    border-bottom: 1px solid #000
    display: flex
    flex-direction column
    // height: 13.586956521739129vh
    margin: 4.830917874396135vw 4.830917874396135vw 0 4.830917874396135vw

    .title
      font-weight: 400
      font-size: calc(1.93237vw + 1.08696vh)
      line-height: calc(2.41546vw + 1.3587vh)
      letter-spacing: .02em;
      text-transform: uppercase
      margin 1.358695652173913vh 2.717391304347826vh 2.717391304347826vh 2.717391304347826vh
    .subtitle
      m-font-size(13)
      m-letter-spacing(20)
      margin-bottom 2.717391304347826vh
    .close
      m-icon("close", 20)
      margin-left: auto

  .list-filters
    flex-wrap: wrap
    height: 13.586956521739129vh
    margin: 0 4.830917874396135vw
    padding: 2.415458937198067vw 0

    .mobile &
      border-bottom: 1px solid rgba(#000, 0.15)
      padding: 2.415458937198067vw 0
      width: 100%

      >>> .filter
        flex: 0 0 50%
        overflow: hidden
        padding: 0 2.415458937198067vw

        &:first-child
          padding-right: 4.830917874396135vw

        .dropdown-button
          width: 100%

  .similars-list
    flex: 1 1 0%
    overflow: hidden
    padding: 4.830917874396135vw

    .scroll-wrapper
      overflow-y: auto

  .similars-list
    .mobile &
      margin-bottom: 1.358695652173913vh

    .scroll-wrapper
      display: flex
      flex-wrap: wrap
      height: 100%
      margin: -2.415458937198068vw

      .mobile &
        margin: -1.207729468599034vw

      .perfume-card
        $margin-x = 2.415458937198068vw
        // si tiene que ser "menos de 4 por fila" hay que sobreescrivir en la vista correspondiente (como en PerfumeAndSimilars que hay un 3)
        $percentage = percentage((0.5))
        $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
        flex: 0 0 $width
        height: 48.309178743961354vw // #212 keep perfume box
        // @media screen and (min-device-width : 768px) and (max-device-width : 1024px)
        // +m-breakpoint(md xmd)
        // height: vw(320px)
        margin: $margin-x auto

        overflow: hidden

        .mobile &
          height: 48.309178743961354vw
          $margin-x = 1.207729468599034vw
          $percentage = percentage((1 / 2))
          $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
          flex: 0 0 $width !important
          margin: $margin-x !important

  .loading-overlay
    .explore-by-concepts &
      position: fixed

      >>> .content
        justify-content: center
        margin-top: 0

    >>> .content
      margin-top: 30vh

  .no-results
    display: flex

    .text
      margin: 2.415458937198067vw auto

  .register-container
    position: sticky;
    top: 0;
    z-index: 20;
    .c-button
      background: linear-gradient(135deg,#eb85a6,#5762aa,#eb85a6);
      background-size: 200%;
      box-shadow: 8px 4px 16px -6px rgb(0 0 0 / 54%);
      min-width: 140px;
      text-align: center;
      display: inline-block;
      border-radius: 40px;
      margin-top: 4.830917874396135vw;
    .c-button__text
      font-size: clamp(12px,4.6vw,56px);
      line-height: 1em;
      letter-spacing: 0;
      color: white;
      font-weight bold;
      display: inline-block;
      width: auto;
      padding: 15px 40px 20px;
</style>
