import store from '../../store'
import router from '../../router'
import Api from "../../Api";

export function getPerfumes(ids){
  return getPerfumesData(ids);
}

export async function getPerfumeQuad(url) {
    return await fetch(url).then(r => r.blob());
}

export function getPerfume(id) {
  // let lang = store.getters.lang
  let cached = store.getters.getById(id)
  if (cached)
    return new Promise(function (resolve) {
      resolve(cached)
    })
  return Api.post(`/fragranceFinderAPI`,
      {
          id: id
      })
    .then(response => {
      store.dispatch('saveById',{id:id, data: response.data?response.data.findPerfumeById:null})
      return response.data.findPerfumeById;
    })
}

export function getPerfumesData(ids) {
    // let lang = store.getters.lang
    let cached;
    let cachedPerfumes = [];
    let idsQuery = [];

    for (let i=0; i<ids.length; i++) {
        cached = store.getters.getById(ids[i]);
        if (cached) {
            cachedPerfumes.push(cached);
        }
        else {
            idsQuery.push(ids[i]);
        }
    }

    return idsQuery.length===0?cachedPerfumes:Api
        .post(`/fragranceFinderAPI`,
            {
                ids: idsQuery
            }
        )
        .then(async response => {
            if (response.data && response.data.findPerfumes) {
                const perfumes = response.data.findPerfumes;
                for (let i = 0; i < perfumes.length; i++) {
                    store.dispatch('saveById', {
                        id: perfumes[i].id,
                        data: perfumes[i]
                    })
                }
            }
            let morePerfumes = [];
            if (idsQuery.length>500) {
                morePerfumes = await getPerfumesData(idsQuery.slice(500));
                if (!morePerfumes) morePerfumes = [];
            }

            let perfumes = response.data.findPerfumes ? morePerfumes.concat(cachedPerfumes.concat(response.data.findPerfumes)) : null;
            if (perfumes) {
                let sorted = [];
                for (let i=0; i<ids.length; i++) {
                    const perfume = perfumes.filter(perfume => perfume.id === ids[i])[0];
                    if (perfume) sorted.push(perfume);
                }
                return sorted
            }
            return perfumes;
        })
        .catch(err => console.log(err));
}
export function track (event) {
  let { data, ...partialEvent } = event
  let eventTransmitted = {
    ...partialEvent,
    data: data,
    app: 'fragrance_qr',
    uuid: router.currentRoute.query.uuid
  }
  console.log(eventTransmitted)

  return
}
