<template>
  <div :class="['app',deviceClass]">
    <div class="app-header">
      <div class="title">
<!--        <svg viewBox="0 0 27 10.66" xmlns="http://www.w3.org/2000/svg" class="b-header__logo-tinder">-->
<!--          <g xmlns="http://www.w3.org/2000/svg" data-name="Capa 2">-->
<!--            <g data-name="Logo T">-->
<!--              <g>-->
<!--                <g class="f4599774-8ed8-46b1-8685-4d0d55d97dfb">-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M6.47,7.75H6.06V7.19h.41V7.05a.8.8,0,0,1,.84-.86,1,1,0,0,1,.47.11l-.14.46a.35.35,0,0,0-.21-.06c-.18,0-.3.13-.3.36v.13h.59v.56H7.13v2.91H6.47Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M7.82,7.19h.65v.33a1.06,1.06,0,0,1,.79-.39v.63H9.08A.87.87,0,0,0,8.47,8V9.65H7.82Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M10.82,9.4a1,1,0,0,1-.78.32.82.82,0,0,1-.86-.82A.78.78,0,0,1,10,8.11a1,1,0,0,1,.78.3V8.07c0-.25-.22-.41-.54-.41A1,1,0,0,0,9.56,8l-.25-.44a1.65,1.65,0,0,1,1.08-.39c.56,0,1.08.23,1.08.94V9.65h-.65Zm0-.64a.63.63,0,0,0-.52-.22c-.26,0-.47.14-.47.37s.21.37.47.37a.63.63,0,0,0,.52-.22Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M12,9.82a1,1,0,0,0,.78.31.61.61,0,0,0,.68-.63V9.25a1,1,0,0,1-.77.39c-.62,0-1.09-.43-1.09-1.26a1.11,1.11,0,0,1,1.09-1.26,1,1,0,0,1,.77.38V7.19h.65v2.3c0,.94-.73,1.17-1.33,1.17a1.51,1.51,0,0,1-1.07-.37ZM13.5,8a.73.73,0,0,0-.56-.28.62.62,0,0,0-.63.68.61.61,0,0,0,.63.68.73.73,0,0,0,.56-.28Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M14.47,7.19h.65v.33a1,1,0,0,1,.79-.39v.63h-.18a.85.85,0,0,0-.61.27V9.65h-.65Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M17.46,9.4a1,1,0,0,1-.78.32.83.83,0,0,1-.86-.82.79.79,0,0,1,.86-.79,1,1,0,0,1,.78.3V8.07c0-.25-.21-.41-.54-.41A1,1,0,0,0,16.2,8L16,7.51A1.65,1.65,0,0,1,17,7.12c.56,0,1.07.23,1.07.94V9.65h-.65Zm0-.64a.62.62,0,0,0-.52-.22c-.25,0-.46.14-.46.37s.21.37.46.37a.62.62,0,0,0,.52-.22Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M20,8.16a.4.4,0,0,0-.45-.46A.68.68,0,0,0,19,8V9.65h-.65V7.19H19v.32a1.11,1.11,0,0,1,.86-.39.72.72,0,0,1,.8.79V9.65H20Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M22.22,7.12a1.14,1.14,0,0,1,1,.45l-.42.4a.62.62,0,0,0-.53-.27.67.67,0,0,0-.67.72.67.67,0,0,0,.67.72.62.62,0,0,0,.53-.27l.42.4a1.14,1.14,0,0,1-1,.45,1.26,1.26,0,0,1-1.31-1.3A1.26,1.26,0,0,1,22.22,7.12Z"></path>-->
<!--                  <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M24.36,7.12A1.24,1.24,0,0,1,25.6,8.48v.15H23.76a.66.66,0,0,0,.71.57A1,1,0,0,0,25.13,9l.29.42a1.52,1.52,0,0,1-1,.35,1.25,1.25,0,0,1-1.32-1.3A1.26,1.26,0,0,1,24.36,7.12Zm-.61,1.07H25a.58.58,0,0,0-.61-.55A.6.6,0,0,0,23.75,8.19Z"></path>-->
<!--                </g>-->
<!--                <path class="f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9" d="M10.2.63A.61.61,0,1,1,9.59,0,.61.61,0,0,1,10.2.63Zm2.76,1a1.94,1.94,0,0,0-1.33.54V1.71h-1V5.87h1V3a1.23,1.23,0,0,1,.95-.49c.48,0,.71.21.73.68h0V5.87h1V3A1.24,1.24,0,0,0,13,1.61ZM23.07,3.87v.28H20a1.14,1.14,0,0,0,1.19,1,1.61,1.61,0,0,0,1.08-.42l.07-.07.48.7,0,0a2.35,2.35,0,0,1-1.67.6,2.07,2.07,0,0,1-2.17-2.17A2.1,2.1,0,0,1,21,1.61,2.07,2.07,0,0,1,23.07,3.87Zm-1-.49a1,1,0,0,0-1-.91A1,1,0,0,0,20,3.38ZM17.67.19h1V5.87h-1V5.44A1.68,1.68,0,0,1,16.46,6,1.92,1.92,0,0,1,14.6,3.79a1.93,1.93,0,0,1,1.86-2.18,1.69,1.69,0,0,1,1.21.53Zm0,2.83a1.26,1.26,0,0,0-1-.49c-.63,0-1.05.51-1.05,1.26s.42,1.26,1.05,1.26a1.22,1.22,0,0,0,1-.48Zm7.87-1.4a1.7,1.7,0,0,0-1.23.56V1.71h-1V5.86h1V3.08a1.33,1.33,0,0,1,1-.48,1,1,0,0,1,.26,0l.09,0v-1ZM9.09,5.87h1V1.71h-1Zm-.7-1A.46.46,0,0,1,8.06,5c-.29,0-.31-.3-.31-.4v-2h.82V1.71H7.76V.61h-1v1.1H6.08v.91h.66V4.86A1.1,1.1,0,0,0,7,5.68a1.08,1.08,0,0,0,.8.28h0a1.17,1.17,0,0,0,.86-.28l0,0-.26-.82ZM2.78,0h0s0,0,0,.05a1.93,1.93,0,0,1-1.2,2.36h0a2,2,0,0,1-.26-.91,0,0,0,0,0-.07,0A2.49,2.49,0,0,0,0,3.45,2.43,2.43,0,0,0,2.55,6,2.51,2.51,0,0,0,5.11,3.45,3.91,3.91,0,0,0,2.78,0ZM26.45,0a.55.55,0,0,0-.56.54.55.55,0,0,0,.56.55A.54.54,0,0,0,27,.54.54.54,0,0,0,26.45,0Zm0,.11a.41.41,0,0,1,.41.43.41.41,0,0,1-.41.43A.42.42,0,0,1,26,.54.42.42,0,0,1,26.45.11Zm-.09.5h.06c.07,0,.1,0,.11.08a.44.44,0,0,0,0,.13h.13a.68.68,0,0,1,0-.13.17.17,0,0,0-.1-.13h0a.14.14,0,0,0,.11-.13.13.13,0,0,0-.05-.11.28.28,0,0,0-.19,0h-.2V.82h.12Zm0-.25h.07c.08,0,.12,0,.12.08s-.06.08-.13.08h-.06Z"></path>-->
<!--              </g>-->
<!--            </g>-->
<!--          </g>-->
<!--        </svg>-->
        <img id="logo" src="./assets/images/logo-dufry.png" alt="Logo">
      </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
import device from '@/utils/device';
export default {
  computed:{
    deviceClass(){
      return device
    }
  }
}
</script>
<style lang="stylus">
body
  margin: 0

a
  color: inherit
  text-decoration: none

a:hover
  color: inherit
  cursor: pointer
  text-decoration: none

html *, html :after, html :before
  box-sizing: border-box
</style>
<style lang="stylus" scoped>
$base-font-color = #212121
$base-font-family = Poppins,sans-serif
.app
  color: $base-font-color
  display: flex
  flex-direction: column
  font-family: $base-font-family
  height: 100%
  position: absolute
  text-align: center
  width: 100%
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  .app-header
    background: #000
    color: #fff
    font-size: 24px
    font-weight: bold
    padding: 10px
    display: flex
    justify-content: center
    background: linear-gradient(135deg,#eb85a6,#5762aa,#eb85a6);
    background-size: 200%

    .title
      width 150px

  .f4599774-8ed8-46b1-8685-4d0d55d97dfb
    opacity: 0.5
  .f7b6309b-58fc-4a9f-8c3b-c0dbe6b13bd9
    fill: #fff
  #logo {
    width: 160px;
    max-width 100%
  }
</style>
