<template>
  <div
    itemscope
    itemtype="http://schema.org/Product"
    class="perfume-full-view"
    :class="{minified, onlyquad}"
  >
    <div class="floating-buttons">
      <!-- <div v-if="!isMobile" class="share-button" @click="showShare=data"></div> -->
      <!-- <ShareComponent v-if="!isMobile" :data="data"></ShareComponent> -->
      <div v-if="!isMobile && showClose" class="separator"></div>
      <div v-if="showClose" class="close-button" @click="$emit('close')"></div>
    </div>
    <div v-if="isMobile && minified || tabletFormat" class="header is-mobile-and-minified">
      <div class="image" :style="{backgroundImage: `url(${data.image_url})`}"></div>
      <div class="name-and-brand" :class="{showClose}">
        <div
          itemprop="name"
          class="name"
          :class="nameSizeClass(data.name)"
        >{{perfumeCapitalize(data.name)}}</div>
        <div itemprop="brand" class="brand">{{data.brand.name}}</div>
        <FamiliesWithIcon
          itemprop="additionalProperty"
          v-if="minified || tabletFormat"
          :family="data.family"
          :subfamily="data.secondaryFamily"
        ></FamiliesWithIcon>
      </div>
    </div>
    <div v-else-if="isMobile && onlyquad" class="header is-mobile-and-onlyquad">
      <div class="quad-header">
        <div class="name-and-brand">
          <div
            itemprop="name"
            class="name"
            :class="nameSizeClass(data.name)"
          >{{perfumeCapitalize(data.name)}}</div>
          <div class="brand-and-fam">
            <div itemprop="brand" class="brand">{{data.brand.name}}</div>
            <FamiliesWithIcon
              itemprop="additionalProperty"
              :family="data.family"
              :subfamily="data.secondaryFamily"
            ></FamiliesWithIcon>
          </div>
        </div>
      </div>
      <div class="quad-content">
        <div class="left">
          <div class="image" :style="{backgroundImage: `url(${data.image_url})`}"></div>
          <!-- <AddToFavsButton :data="data"></AddToFavsButton> -->
          <!-- <div class="share-button" @click="showShare=data"></div> -->
          <!-- <ShareComponent :data="data"></ShareComponent> -->
        </div>
        <div class="quad-image" :style="imageQuad?`background-image: url(${imageQuad});`:''">
<!--          <img v-if="imageQuad" :src="imageQuad" @load="URL.revokeObjectURL(imageQuad)">-->
        </div>
      </div>
    </div>
    <div v-else-if="isMobile " class="header is-mobile">
      <div class="name-and-brand">
        <div
          itemprop="name"
          class="name"
          :class="nameSizeClass(data.name)"
        >{{perfumeCapitalize(data.name)}}</div>
        <div class="brand-and-fam">
          <div itemprop="brand" class="brand">{{data.brand.name}}</div>
          <FamiliesWithIcon
            itemprop="additionalProperty"
            :family="data.family"
            :subfamily="data.secondaryFamily"
          ></FamiliesWithIcon>
        </div>
      </div>
    </div>

    <div v-else class="header" ref="subsection0" :style="forcedHeigh(0)">
      <div class="name-and-brand">
        <div
          itemprop="name"
          class="name"
          :class="nameSizeClass(data.name)"
        >{{perfumeCapitalize(data.name)}}</div>
        <div itemprop="brand" class="brand">{{data.brand.name}}</div>
      </div>
    </div>
    <div
      v-if="!minified && !onlyquad"
      class="subsection main"
      ref="subsection1"
      :style="forcedHeigh(1)"
    >
      <div class="left" v-if="!tabletFormat">
        <!-- <div class="family-with-icon">{{data.family.name}}</div> -->
        <FamiliesWithIcon
          itemprop="additionalProperty"
          v-if="!isMobile"
          :family="data.family"
          :subfamily="data.secondaryFamily"
        ></FamiliesWithIcon>
        <div class="image-wrapper">
          <div class="image" :style="{backgroundImage: `url(${data.image_url})`}"></div>
        </div>
        <!-- <AddToFavsButton :data="data"></AddToFavsButton> -->
        <!-- <div v-if="isMobile" class="share-button" @click="showShare=data"></div> -->
        <!-- <ShareComponent v-if="isMobile" :data="data"></ShareComponent> -->
      </div>
      <div class="right">
        <div v-if="data.expert_reviewed" class="expert-reviewed">
          <div class="icon"></div>
          <div class="text">Expert reviewed</div>
        </div>
        <div class="quad-image" :style="imageQuad?`background-image: url(${imageQuad});`:''">
<!--          <img v-if="imageQuad" :src="imageQuad" @load="URL.revokeObjectURL(imageQuad)">-->
        </div>
      </div>
    </div>

    <div
      v-if="!minified && !onlyquad"
      class="subsection ingredients"
      ref="subsection2"
      :style="forcedHeigh(2)"
      itemprop="additionalProperty"
      itemscope
      itemtype="http://schema.org/PropertyValue"
    >
      <div itemprop="name" class="subsection-title">Ingredients</div>
      <div class="subsection-content ingredients-list">
        <div
          itemprop="value"
          v-for="ingredient in data.ingredients"
          :key="ingredient.id"
          class="ingredient-item"
        >{{ingredient.name}}</div>
      </div>
    </div>

    <!-- De momento nada... -->
    <!-- <div v-if="showRecoReason && !minified && !onlyquad" class="subsection reco-reason">
      <div class="subsection-title">{{t('reco_desc_title')}}</div>
      <div class="subsection-content">
        <RecoDesc :data="data"></RecoDesc>
      </div>
    </div> -->

    <div
      v-if="!minified && !onlyquad"
      class="subsection description"
      ref="subsection3"
      :style="forcedHeigh(3)"
    >
      <div class="subsection-title">Description</div>
      <div itemprop="description" class="subsection-content">{{data.description}}</div>
    </div>
    <div
      v-if="!minified && !onlyquad"
      class="subsection family"
      ref="subsection4"
      :style="forcedHeigh(4)"
    >
      <FamilyDonut :family="data.family" :subfamily="data.secondaryFamily"></FamilyDonut>
      <div class="family-desc">
        <div class="subsection-title">Olfactive family</div>
        <div class="subsection-content">
          <div
            itemprop="additionalProperty"
            itemscope
            itemtype="http://schema.org/PropertyValue"
            class="fam-group"
          >
            <div itemprop="name" class="title uppercase">Family</div>
            <div itemprop="value" class="value">{{data.family.name}}</div>
          </div>
          <div
            itemprop="additionalProperty"
            itemscope
            itemtype="http://schema.org/PropertyValue"
            class="fam-group"
            v-if="data.secondaryFamily"
          >
            <div itemprop="name" class="title uppercase">Subfamily</div>
            <div itemprop="value" class="value">{{data.secondaryFamily.name}}</div>
          </div>
          <div
            itemprop="additionalProperty"
            itemscope
            itemtype="http://schema.org/PropertyValue"
            class="fam-group"
            v-if="data.me_family_name"
          >
            <div itemprop="name" class="title">Fragrances of the World</div>
            <div itemprop="value" class="value">{{data.me_family_name}}</div>
          </div>
        </div>
      </div>
    </div>

    <div
      itemprop="additionalProperty"
      itemscope
      itemtype="http://schema.org/PropertyValue"
      v-if="!minified && !onlyquad"
      class="subsection perfumist"
      :class="(canHide(5) && !data.perfumer)?'hidden':''"
      ref="subsection5"
      :style="forcedHeigh(5)"
    >
      <template v-if="data.perfumer">
        <div itemprop="name" class="subsection-title">Perfumist</div>
        <div itemprop="value" class="subsection-content">{{data.perfumer.name}}</div>
      </template>
    </div>
    <!-- REMOVED CONCEPTS UNTIL WE GET THE DATA...  -->
    <!-- <div
      v-if="!minified && !onlyquad"
      class="subsection concepts"
      ref="subsection6"
      :style="forcedHeigh(6)"
    >
      <div class="subsection-title">{{t('concepts')}}</div>
      <div class="subsection-content">
        <div class="concepts-list">
          <ConceptTag color="white" :data="{label: 'fake-concept'}" :showRemove="false"></ConceptTag>
          <ConceptTag color="white" :data="{label: 'fake-concept-2'}" :showRemove="false"></ConceptTag>
          <ConceptTag color="white" :data="{label: 'other-fake'}" :showRemove="false"></ConceptTag>
        </div>
      </div>
    </div>-->

    <div class="similar-button-wrapper" v-if="canShowSimilar">
      <SquareButton @click="$emit('show-similar')">FIND PERFUMES WITH SIMILAR INGREDIENTS</SquareButton>
    </div>
  </div>
</template>

<script>
//import QuadPack from '@/components/wikiparfum/QuadPack/QuadPack'
import FamiliesWithIcon from '@/components/wikiparfum/FamiliesWithIcon'
// import AddToFavsButton from '@/components/wikiparfum/AddToFavsButton'
import SquareButton from '@/components/wikiparfum/SquareButton'
import FamilyDonut from '@/components/wikiparfum/FamilyDonut'
import NameSizeClasses from '@/vue-plugins/NameSizeClasses'
import {getPerfumeQuad} from "@/services/api";
export default {
  name: 'PerfumeFullView',
  components:{
    //QuadPack,
    FamiliesWithIcon,
    // AddToFavsButton,
    SquareButton,
    FamilyDonut,
    // RecoDesc,
    // ShareComponent

    // ConceptTag
  },
  mixins: [NameSizeClasses],
  props: {
    data: Object,
    canShowSimilar: {
      type: Boolean,
      default: false
    },
    forcedHeights: Array,
    minified: {
      type: Boolean,
      default: false
    },
    onlyquad: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    tabletFormat:{
      type: Boolean,
      default: false
    },
    showRecoReason: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ownHeights: null,
      imageQuad: null,
      // showShare: null
    }
  },
  // routerLinkedData:['showShare'],
  methods:{
    forcedHeigh(index){
      // if(this.forcedHeights) return {height: this.forcedHeights[index]+'px'}
      // // else if(this.ownHeights) return {height: this.ownHeights[index]+'px'}
      // else return {height: 'auto'}
      let forcedHeight = this.forcedHeights && this.forcedHeights[index]
      return {height: forcedHeight?(forcedHeight+'px'):'auto'}
    },
    canHide(index){
      if(this.forcedHeights && this.forcedHeights[index]) return false
      else return true
    },
    getHeights(){

      // LIMPIARLO PERO PARECE QEU "IS THE WAY", quitar->medir->reponer
      let idxs = [0,1,2,3,4,5,6]
      let currentsStyles = []
      idxs.forEach((i)=>{
        if(this.$refs['subsection'+i]) {
          currentsStyles[i] = this.$refs['subsection'+i].style.height
          this.$refs['subsection'+i].style.height = 'auto'
        }
      })

      this.ownHeights = idxs.map((i)=>{
        return this.$refs['subsection'+i] && this.$refs['subsection'+i].offsetHeight
      })

      idxs.forEach((i)=>{
        if(currentsStyles[i]) this.$refs['subsection'+i].style.height = currentsStyles[i]
      })
      if(!this.data.perfumer) this.ownHeights[5] = null
      return this.ownHeights
    },

  },
  mounted(){
    // trackPerfumViewed({id:this.data.id})
    this.$nextTick(()=>{
      this.$emit('update')
    });
    getPerfumeQuad(this.data.quadrification_url.width500).then(quad => {
      this.imageQuad = window.URL.createObjectURL(new Blob([quad]));
    });
  },
  watch:{
    data () {
      this.$nextTick(()=>{
        this.$emit('update')
      })
    }
  }
}
</script>

<style scoped lang="stylus">
$similar-button-wrapper-height = 13.586956521739129vh

.perfume-full-view
  background-color: #fff
  margin: auto
  padding: 4.830917874396135vw
  padding-bottom: 10vh // por el boton flotante - no se si debe ir "siempre o no"
  pointer-events: all
  position: relative
  width: 100%

  .mobile &
    padding: 2.415458937198068vw
    padding-bottom: $similar-button-wrapper-height

  &.minified
    padding-bottom: 0

  &.onlyquad
    height: 100%
    padding-bottom: 1.358695652173913vh
    // padding-top: 0

  .floating-buttons
    align-items: center
    display: flex
    position: absolute
    right: 4.830917874396135vw
    top: 2.717391304347826vh

    .tablet &
      top: 1.358695652173913vh

    .mobile &
      right: 2.898550724637681vw

    .separator
      background-color: rgba(#000, 0.15)
      height: 3.396739130434783vh
      margin: 0 3.623188405797102vw
      width: 1px

  // los saco de "floating-buttons" para cuando el share esta debajo del favs button
  .close-button
    cursor: pointer
    background-image: url(../../assets/images/icon-close.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: calc(100% - 1px) calc(100% - 1px);
    height: 3.623188405797102vw;
    width: 3.623188405797102vw;

  // .share-button
  // cursor: pointer
  // m-icon("share", 15)
  // // DESHABILITAMOS los share buttons hasta nueva orden!!!!
  // // visibility hidden

  // .mobile &
  // m-icon("share", 20)
  .header
    border-bottom: 1px solid rgba(#000, 0.15)
    padding: 0 16.908212560386474vw 2.717391304347826vh // top right bottom
    text-align: center

    &.is-mobile
      border-bottom: none

    &.is-mobile,
    &.is-mobile-and-onlyquad
      display: flex
      margin: 0
      overflow: hidden
      padding: 0

      .name-and-brand
        align-items: flex-start
        display: flex
        flex-direction: column
        margin-bottom: 1.358695652173913vh
        overflow: hidden
        width: 100%

        // .name
        // margin-bottom: 0
        .brand-and-fam
          align-items: center
          display: flex
          overflow: hidden
          width: 100%

          .name
            margin-right: 19.323671497584542vw
            text-align: left

          .brand
            margin-right: 2.415458937198067vw
            max-width: 50%
            padding-right: 2.415458937198067vw
            position: relative

            &:after
              background-color: rgba(#000, 0.15)
              content: ""
              height: 100%
              position: absolute
              right: 0
              top: 0
              width: 1px

          >>> .family-and-icon
            max-width: 50%
            overflow: hidden

            // margin-top: 1.207729468599034vw
            .icon
              margin-right: 0.966183574879227vw

            .label
              display: block

    &.is-mobile-and-minified
      display: flex
      margin: 0
      padding: 0
      padding-bottom: 2.717391304347826vh

      .image
        flex: 0 0 16.908212560386474vw // ALGO ASI...
        margin: 0

      .name-and-brand
        align-items: flex-start
        overflow: hidden

        &.showClose
          padding-right: 21.739130434782609vw

        .name
          m-ellipsis(100%)

        .brand
          margin-bottom: 0.679347826086956vh

        >>> .family-and-icon
          margin-top: 1.207729468599034vw

          .icon
            margin-right: 0.543478260869565vh

    &.is-mobile-and-onlyquad
      display: flex
      flex-direction: column
      // padding: 0
      height: 100%
      padding-bottom: 2.717391304347826vh
      width: 100%

      .similar-perfumes-wrapper &
        border-bottom: none
        padding-bottom: 0

        .name
          margin-right: 9.661835748792271vw

      .quad-header
        display: flex
        margin-bottom: 1.358695652173913vh

        // flex: 0 0 auto
        .name-and-brand
          align-items: flex-start
          display: flex
          flex-direction: column

          .name
            text-align: left
            m-ellipsis(100%)

          .brand-and-fam
            display: flex

      .quad-content
        display: flex
        flex: 1 1 0%

        .left
          align-items: center
          display: flex
          flex: 0 0 25%
          flex-direction: column
          justify-content: space-evenly

          .image-wrapper
            height: 100%
            padding: 5.434782608695651vh 9.661835748792271vw
            width: 100%

            .image
              height: 36vh
              margin: 4.830917874396135vw
              width: 100%

          >>> .add-to-favs-button
            margin: 1.358695652173913vh auto

            .icon
              m-icon("add", 20)
              margin-bottom: 0

              &.faved
                m-icon("remove", 20)

            .label
              display: none

          >>> .share-component
            .share-panel
              bottom: "calc(100% + %s)" % 1.630434782608696vh
              left: -0.815217391304348vh
              right: inherit
              top: inherit

        .quad-pack
          flex: 1 1 0%

    .image
      background-position: center
      background-repeat: no-repeat
      background-size: contain
      flex: 1 1 0%
      margin: 2.717391304347826vh 0
      width: 90%

    .name-and-brand
      align-items: center
      display: flex
      flex-direction: column
      height: 100%
      justify-content: center

      .mobile &
        margin-right: 7.246376811594203vw
        overflow: hidden
        padding: 2.415458937198067vw

      .name
        font-size: calc(2.89855vw + 1.63043vh);
        line-height: calc(3.86473vw + 2.17391vh);
        margin-bottom: .679347826086957vh;

        .mobile &
          font-size: calc(2.89855vw + 1.63043vh) // no esta bien, porque si el nombre es largo "se sale..."
          padding-right: 9.661835748792271vw
          text-align: left

      .brand
        max-width: 100%
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        font-size: calc(1.69082vw + .95109vh)
        line-height: calc(2.05314vw + 1.15489vh)
        letter-spacing: .016em
        text-transform: uppercase

        .mobile &
          m-font-size(12, 15)

  .similar-button-wrapper
    bottom: 2.415458937198067vw
    display: flex
    justify-content: center
    left: 10%
    position: fixed
    width: 80%
    z-index: 1

    .square-button
      margin-bottom: 1.358695652173913vh
      z-index: 1

      .mobile &
        height: $similar-button-wrapper-height
        margin: 0
        // chapuzilla para qeu "FINd PERFUMES WITH SIMILAR INGREDIENTS" salga 1-liner
        padding: 0
        width: 100%

  .subsection
    border-bottom: 1px solid rgba(#000, 0.15)
    margin: 2.717391304347826vh 0
    padding-bottom: 2.717391304347826vh
    padding-left: 3.623188405797102vw
    padding-right: 3.623188405797102vw
    transition: height 0.4s

    .mobile &
      margin: 5.434782608695651vh 0
      padding-bottom: 6.793478260869565vh

    &.hidden
      border: none
      height: auto
      margin: 0
      padding: 0

    &.main
      display: flex
      margin-bottom: 2.717391304347826vh
      margin-top: 0
      overflow: hidden
      padding: 0

      .mobile &
        display: block

        .left,
        .right
          border: none
          max-width: none

        .left
          position: relative

          .image
            flex: 0 0 36vh !important
            height: 36vh !important

          .share-component
            bottom: 0
            bottom: 1.358695652173913vh
            margin: 2.717391304347826vh
            margin: 0
            position: absolute
            right: 0

            >>> .share-panel
              bottom: "calc(100% + %s)" % 2.445652173913043vh
              left: inherit
              right: 0.543478260869565vh
              top: inherit

        .right
          .expert-reviewed
            border-top: 1px solid rgba(#000, 0.15)
            justify-content: flex-start
            margin-bottom: 2.717391304347826vh
            margin-top: 2.717391304347826vh
            padding-top: 2.717391304347826vh

      .left,
      .right
        padding: 1.358695652173913vh 4.830917874396135vw

        .mobile &
          padding: 1.358695652173913vh 0

      // NO ME GUSTA NADA AQUI,
      // .half-size &
      // .left
      // transition flex 1.4s
      // flex: 0 0 auto

      // .right
      // transition flex 1.4s
      // flex: 1 1 0
      .left
        border-right: 1px solid rgba(#000, 0.15)
        display: flex
        flex: 1 1 0%
        flex-direction: column

        .family-and-icon
          >>>.label
            white-space: normal

        .image-wrapper
          height: 100%
          padding: 2vw
          width: 100%

          .image
            background-position: center
            background-repeat: no-repeat
            background-size: contain
            flex: 1 1 13.586956521739129vh
            height: 100%
            margin: 1.358695652173913vh 0
            width: 100%

      .right
        display: flex
        flex: 1 1 20% // auto
        flex-direction: column
        justify-content: center // flex-end // #224
        max-width: 50vh

        .expert-reviewed
          align-items: center
          display: flex
          justify-content: flex-end
          margin-bottom: 2.717391304347826vh

          .icon
            background-image: url(../../assets/images/icon-expert-reviewed.svg)
            background-position: 50%
            background-repeat: no-repeat
            background-size: calc(100% - 1px) calc(100% - 1px)
            height: 4.347826086956522vw
            width: 4.347826086956522vw
            margin-right: 1.207729468599034vw

          .text
            text-transform: capitalize
            font-size: calc(1.3285vw + .74728vh)
            line-height: calc(1.57005vw + .88315vh)

    .subsection-title
      font-weight: 700
      font-size: calc(1.3285vw + .74728vh)
      line-height: calc(1.57005vw + .88315vh)
      margin-bottom: 2.717391304347826vh
      text-align: left
      text-transform: uppercase

      .mobile &
        m-font-size(15, 17)

    .subsection-content
      font-size: calc(1.69082vw + .95109vh)
      line-height: calc(2.89855vw + 1.63043vh)
      letter-spacing: .025em
      text-align: left

  .subsection
    &.main
      .mobile &
        border: none

    &.ingredients
      .ingredients-list
        display: flex
        flex-wrap: wrap
        margin-left: -2.415458937198068vw
        margin-right: -2.415458937198068vw

        .ingredient-item
          margin: 0.679347826086956vh 2.415458937198067vw

          &:not(:last-child)
            position: relative

            &:after
              background: rgba(#000, 0.15)
              content: ""
              height: 100%
              position: absolute
              right: -2.415458937198068vw
              top: 0
              width: 1px

    &.family
      align-items: center
      display: flex
      flex-direction: row

      .family-donut
        flex: 0 0 24.154589371980677vw
        height: 24.154589371980677vw
        margin-right: 4.830917874396135vw
        width: 24.154589371980677vw

      .family-desc
        .subsection-content
          display: flex
          flex-wrap: wrap

          .fam-group
            margin: 0.679347826086956vh 0

            &:not(:last-child)
              margin-right: 9.661835748792271vw

            .title
              m-font-size(11, 13)
              margin-bottom: 0.679347826086956vh
              opacity: 0.5

            .uppercase
              m-first-letter-case()

    &.concepts
      .concepts-list
        display: flex
        flex-wrap: wrap
        margin: -0.679347826086956vh -1.207729468599034vw

        .concept-tag
          margin: 0.679347826086956vh 1.207729468599034vw
  .quad-image
    background-position: center
    background-repeat: no-repeat
    background-size: contain
    flex: 1 1 35vh
    height: 100%
    width: 100%
</style>
