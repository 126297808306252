<template>
  <div class="recos-view">
    <recommendations :results="results" @select="onPerfumeClick"></recommendations>
  </div>
</template>

<script>
import Recommendations from '@/components/wikiparfum/Recommendations';
import {getPerfumes, track} from '@/services/api';
import Api from "../Api";

export default {
  components:{Recommendations},
  mounted(){
    track({
      name: 'pageView',
      data: this.recosIds
    });
    const WEBSITE_NAME = 'FRAGRANCE_FINDER';
    const TYPE = 'results';
    const ep = `/visitorcounter?type=${TYPE}&name=${WEBSITE_NAME}`;
    Api.post(ep)
        .catch(err => console.log(err));
  },
  asyncComputed:{
    results (){
      return getPerfumes(this.recosIds)
    }
  },
  computed:{
    recosIds(){
      return this.$route.query.recos?this.$route.query.recos.split(','):[];
    }
  },
  methods:{
    onPerfumeClick(perfume){
      this.$router.push(`/perfume/${perfume.id}`)
    }
  }
}
</script>

<style scoped lang='stylus'>
.recos-view
  // background $flamingo
  flex: 1 1 auto;
  overflow: auto;
</style>
