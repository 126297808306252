<template>
  <div itemscope itemtype="http://schema.org/PropertyValue" class="family-and-icon" >
    <div class="icon">
      <div class="outer" :style="{background: family.color}"></div>
      <div  class="inner" :style="{background: subfamily ? subfamily.color:family.color}"></div>
    </div>
    <meta itemprop="name" content="family"/>
    <div itemprop="value" class="label" v-tooltip.top="label">{{label}}</div>
  </div>
</template>

<script>
import {VTooltip} from 'v-tooltip'
export default {
  name: 'FamilyWithIcon',
  components:{
  },
  directives:{
    tooltip: VTooltip
  },
  props: ['family','subfamily'],
  methods:{
  },
  computed:{
    label (){
      if(this.family.name){
        if(this.subfamily)
          return this.family.name + ' / ' + this.subfamily.name
        return this.family.name
      } else
        return ''
    }
  }
}
</script>

<style scoped lang="stylus">
$fw-medium = 500
.family-and-icon
  align-items: center
  display: flex
  cursor pointer
  text-align: left;
  .icon
    //added 2 extra pixels for deciaml cutting
    height: 5.314009661835749vw
    margin-right: 2.415458937198068vw
    position: relative
    width: @height

    // padding: vw(9px) //sirve para evitar que corte por "medios pixeles"
    .mobile &
      margin-right: 1.207729468599034vw

    .outer,
    .inner
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)

    .outer
      border-radius: 50%
      height: 4.830917874396135vw
      width: @height

    .inner
      border: solid 1px rgba(255, 255, 255, 1)
      border-radius: 50%
      height: 2.415458937198068vw
      width: @height

    .mobile &
      //added 2 extra pixel for deciaml cutting
      width: 4.106280193236715vw

      .outer
        height: 3.623188405797102vw
        width: @height

      .inner
        height: 1.690821256038647vw
        width: @height

  .label
    flex: 1 1 0
    m-ellipsis(100%)
    m-font-size(11, 13)
    align-items: center
    // display: flex
    font-weight: $fw-medium
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calc(1.3285vw + .74728vh);
    line-height: calc(1.57005vw + .88315vh);

    // Para ue quede un poco mejor el tooltip...
    // height: 100%
    .mobile &
      m-font-size(11, 14)
</style>
