import axios from 'axios'
import store from '@/store.js'

const apiUrl = process.env.VUE_APP_API_EP

let Api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Accept': 'application/json'
  }
});

Api.interceptors.request.use (
  function (config) {
    const token = store.getters.token;
    if (token) config.headers.Authorization = `${token}`;
    // console.log(token);
    return config;
  },
  function (error) {
    if(error.response.status === 403){
      // Show login dialog and retry request
      alert("Error 403, please contact system manager");
    } else {
      alert("Unknown error. See logs");
      console.log(error);
    }
  }
);

Api.interceptors.response.use(
  function(response) {
    // console.log("ERROR FOUND");
    // console.log(response);
    if(response.headers["x-amzn-errortype"]=="UnauthorizedException") {
      location.reload();
    } else {
      return response;
    }
  },
  function (error) {
    //review this
    // console.log("ERROR FOUND");
    // console.log(JSON.stringify(error.config));
    if(error.headers=="UnauthorizedException") {
      location.reload();
    } else {
      //alert("Please refresh the page");
      return error;
    }
  }
);

export default Api
